@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-family: 'Montserrat', sans-serif !important;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
main {
  display: flex;
  min-height: 100vh;
}
aside {
  width: 250px;
  flex-shrink: 0;
  background: #f7f7f7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
aside li {
  width: 100%;
  list-style: none;
}
aside li a {
  display: block;
  width: 100%;
  padding: 13px;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #6c6c6c;
  text-decoration: none;
  font-size: 14px;
}
aside li a.active {
  background: #5d8c51;
  color: #FFFFFF;
}
article {
  flex-grow: 1;
}

.w100 {
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}
.btn {
  background: #000;
  color: #fff;
  border-radius: 4px;
  padding: 13px 20px;
  border: none;
  margin-top: 5px;
  cursor: pointer;
}
.btn:disabled {
  opacity: 0.5;
  cursor: default;
}
.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(000,000,000,0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.modal-container {
  width: 600px;
  background: #FFFFFF;
  z-index: 2;
  padding: 20px;
  border-radius: 10px;
}
.modal-container__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 8px;
}
.modal-container__heading p {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.modal-container__heading span {
  font-size: 30px;
  cursor: pointer;
}
.modal-container__body {
  padding-top: 10px;
}
.input {

}
.input input, .input textarea, .input select {
  width: 100%;
  max-width: 100%;
  background: #f7f7f7;
  outline: none;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid #bbb;
  padding: 5px 10px;
  border-radius: 4px;
}
.input textarea {
  height: inherit;
}
.input label {
  font-size: 13px;
  color: #737373;
  font-weight: 500;
  margin-top: 5px;
  display: block;
  margin-bottom: 2px;
}
.input span {
  font-size: 12px;
  color: #d20000;
}
.input-unvalid input {
  border: 1px solid #d20000;
}
.choose_to_do li {
  cursor: pointer;
  padding: 5px 0px;
  list-style: disc;
  margin-left: 20px;
}
.choose_to_do li:hover {
  text-decoration: underline;
}
.send_message_block {
  position: relative;
}
.send_message_block textarea {
  padding-right: 30px;
}
.send_message_block__emoji {
  position: absolute;
  right: 0;
  top: 18px;
  z-index: 2;
}
.send_message_block__emoji b {
  font-size: 30px;
}
.send_message_block__picker {
  position: absolute;
  display: none;
}
.send_message_block__emoji:hover .send_message_block__picker {
  display: block;
}
.custom-table {
  width: calc(100% - 40px);
  margin-top: 20px;
  margin-left: 20px;
}
.custom-table th {
  text-align: left;
  padding: 12px;
  background: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-left: 0;
  color: #8e8e8e;
  font-size: 13px;
}
.custom-table th:first-child {
  border-left: 1px solid #dcdcdc;
}
.custom-table td {
  text-align: left;
  padding: 8px;
  border: 1px solid #dcdcdc;
  border-left: 0;
  vertical-align: middle;
  font-size: 13px;
}
.custom-table td:first-child {
  border-left: 1px solid #dcdcdc;
}
.custom-table .icon-grey {
  color: #787878;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
}
.custom-table .icon-red {
  color: #e14040;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #5d8c51;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.modal-loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(#00583f, transparent);
  z-index: 100;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.custom-node {
  background: #FFFFFF;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  width: 220px;
  position: relative;
}
.custom-node__report {
  position: absolute;
  display: flex;
  font-size: 7px;
  padding: 5px;
  background: #e0ffe0;
  top: -17px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px 2px 0 0;
  justify-content: space-around;
  align-items: center;
}

.custom-node__heading {
  background: #e5eaf0;
  padding: 9px 15px;
  font-size: 10px;
  text-transform: uppercase;
}
.custom-node__body {
  padding: 8px;
}
.node-btn {
  background: #cbcbcb;
  color: #1e1e1e;
  border-radius: 4px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.custom-checkbox__squire {
  width: 18px;
  height: 18px;
  border: 2px solid #000000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-checkbox__squire-active {
  width: 14px;
  height: 14px;
  background: #5d8c51;
  border-radius: 2px;
}
.custom-checkbox__label {
  font-size: 13px;
  margin-left: 3px;
  color: #2b2b2b;
}
#json_editor {
  line-height: 1.5 !important;
  font-size: 14px !important;
  transform: none !important;
}
.picture-modal__file {
  position: relative;
  cursor: pointer;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #c3c3c3;
  padding: 0 10px;
  box-sizing: border-box;
}
.picture-modal input {
  display: none;
  /*width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;*/
}
.picture-modal__text {
  font-size: 12px;
  color: #818181;
  text-transform: uppercase;
}

.pagination {
  display: flex;
  align-items: center;
  float: right;
  padding: 5px 10px;
  /*background: #fff;
    border: 2px solid #669295;*/
}
.pagination__arrow-left {
  cursor: pointer;
}

.pagination__container {
  display: flex;
  margin: 0 20px;
}

.pagination__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 5px;
  /*background-color: #f6f6f6;*/
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.pagination__item:last-child {
  margin-right: 0;
}

.pagination__item--active {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.pagination__item-dots {
  background-color: transparent;
  color: #bbb;
}
.pagination__arrow-right {
  transform: rotate(180deg);
  cursor: pointer;
}
.weekend-page {
  width: 100%;
  position: relative;
}
.weekend-page-td:hover {
  background: darkgrey !important;
}